import React, { FunctionComponent, ReactNode } from "react";
import { PageProps, graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HtmlInfoText from "../components/html-info-text";
import { extractHtml, extractImage } from "../util";
import Content from "../components/content";
import classes from "./index.module.css";
import Button from "../components/button";
import Hero from "../components/hero";

const query = graphql`
    query {
        introduction: markdownRemark(fileAbsolutePath: {regex: "/home.md/"}) {
            html
        }
    }
`;

const IndexPage: FunctionComponent<PageProps> = ({ location }) => {
    const data = useStaticQuery(query);
    const { introduction } = extractHtml(data);

    return (
        <Layout currentPath={location.pathname}>
            <SEO title="Home" />
            <Hero small={false} />

            <Content>
                <HtmlInfoText html={introduction} className={classes.introText} />
            </Content>
        </Layout>
    );
};

export default IndexPage;
